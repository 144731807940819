import React, { useContext, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import './_GeneralLayout.scss';
import { useLocation } from 'react-router-dom';
import { MainContext } from '../../state/MainContext';
import { allowTransition, setNextLevel, setSoundSrc } from '../../state/MainActions';
import AboutContainer from '../containers/about-container';
import NavContainer from '../containers/nav-container';
import { TweenMax } from 'gsap/gsap-core';
const GeneralLayout = ({ children, history }) => {
  const location = useLocation();
  const menuRef = useRef(null);
  const aboutRef = useRef(null);
  const mainRef = useRef(null);


  const [state, dispatch] = useContext(MainContext);
  const [showScroll, setShowScroll] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [navStyle, setNavStyle] = useState('nav-visible');
  // const [showNavOnMove, setShowNavOnMove] = useState(false);

  useEffect(() => {
    let timeout = 0;

    const toggleMenuVisibility = () => {
      if (!state.system.iOS) {
        // console.log('MOVE', location.pathname)
        if (location.pathname !== '' && location.pathname !== '/the-end') {
          setNavStyle('nav-visible')
          clearTimeout(timeout);
          
          timeout = setTimeout(() => {
            // console.log(location.pathname, state.nav, state.about )
            if (location.pathname === '/the-pond' || location.pathname === '/the-river') {
              if (!state.nav.expanded && !state.about.expanded) {
                setNavStyle('nav-hidden')
              }
            } else {
              setNavStyle('nav-visible')
            }
          }, 3000);
        }
      }
    }

    window.addEventListener('mousemove', toggleMenuVisibility)

    return () => {
      window.removeEventListener('mousemove', toggleMenuVisibility);
    }
  }, [location.pathname])

  useEffect(() => {

  }, [location.pathname])

  useEffect(() => {
    if (showScroll) {
      gsap.set('.pond-scroll', { autoAlpha: 0 });
    }
  }, [state.currentLevel, location.pathname]);

  useEffect(() => {
    let nav = false;
    let about = false;
    let scroll = false;
    if (state.currentLevel >= 0) {
      if (location.pathname !== '' && location.pathname !== '/where-to-next' && location.pathname !== '/the-end' && !state.transitionPlaying) {
        if (!state.nav.expanded) {
          about = true;
        }
        if (!state.about.expanded) {
          nav = true;
        }
      }
      if (location.pathname === '/the-pond' || location.pathname === '/the-river') {
        if (!state.nav.expanded && !state.about.expanded) {
          scroll = true;
        }
      }
    }
    // console.log('::::::::', about, nav, scroll)

    setShowAbout(about);
    setShowNav(nav);
    setShowScroll(scroll)
  }, [
    state.currentLevel,
    state.scrollToNext,
    state.transitionPlaying,
    state.nav.expanded,
    state.about.expanded,
    location.pathname,
  ]);

  useEffect(() => {
    let scrollDelay = 0
    if (showScroll) {
      if (location.pathname === '/the-pond') {
        scrollDelay = parseInt(process.env.REACT_APP_POND_SCROLL_DELAY)
      } else if (location.pathname === '/the-river') {
        scrollDelay = parseInt(process.env.REACT_APP_RIVER_SCROLL_DELAY)
      } 

      gsap.killTweensOf('.pond-scroll');
      gsap.to('.pond-scroll', {
        autoAlpha: 1,
        duration: 1,
        delay: scrollDelay,
      });
    }
  }, [showScroll, location.pathname]);

  const advanceToNextLevel = (e) => {
    if (!state.nav.expanded && !state.about.expanded) {
      // window.removeEventListener('scroll', handleScroll);
      gsap.set('.pond-scroll', { duration: 0.5, autoAlpha: 0 });
      dispatch(allowTransition(true));
      dispatch(setSoundSrc(''));
      dispatch(setNextLevel(state.currentLevel + 1));
    }
  };



  return (
    <main className='layout' ref={mainRef}>
      <section className='layout--container'>{children}</section>
      {showScroll && (
        <div className='pond-scroll' onClick={advanceToNextLevel}>
          <div>Continue</div>
          <div>
            <img src='/assets/images/DownArrow.png' alt='scroll to navigate to the next section' />
          </div>
        </div>
      )}
      {showAbout && <div ref={aboutRef} className={`${navStyle}`}><AboutContainer /></div>}
      {showNav && <div ref={menuRef} className={`${navStyle}`}> <NavContainer /></div>}
    </main>
  );
};

export default GeneralLayout;