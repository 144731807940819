import { useContext, useEffect, useState } from 'react';
import gsap, { Expo } from 'gsap';

import { MainContext } from '../../../state/MainContext';
import './nav-tree.scss';
import { setNextLevel, toggleNavigation, setSoundSrc } from '../../../state/MainActions';

const NavTree = ({ setHovered }) => {
  const [state, dispatch] = useContext(MainContext);

  const onNavItemClicked = (slug) => {
    const index = state.levels.findIndex((el) => el.slug === slug);
    // console.log(`setNextLevel(${index})`);
    dispatch(toggleNavigation(false));
    dispatch(setNextLevel(index));
  };

  useEffect(() => {
    state.levels
      .filter((element) => element.show)
      .forEach((element, index) => {
        gsap.fromTo(
          `#nav_tree_item_${index}`,
          {
            autoAlpha: 0,
            x: '20px',
          },
          {
            autoAlpha: state.nav.expanded ? 1 : 0,
            x: state.nav.expanded ? 0 : '20px',
            duration: 0.4,
            delay: index * 0.1,
            ease: Expo.easeOut,
          }
        );
      });
  }, [state.nav.expanded]);

  return (
    <div className='nav-tree'>
      {state.levels
        .filter((element) => element.show)
        .map((element, index) => {
          return (
            <div
              key={`nav_tree_item_${index}`}
              id={`nav_tree_item_${index}`}
              className='nav-tree-item'
              onClick={() => {
                if (element.slug === '/the-pond') {
                  dispatch(setSoundSrc(`/assets/sounds/ambience.mp3`));
                } else {
                  dispatch(setSoundSrc(``));
                }
                setHovered(-1);
                onNavItemClicked(element.slug);
              }}
              onMouseOver={() => {
                setHovered(index);
              }}
              onMouseLeave={() => {
                setHovered(-1);
              }}
            >
              <div className='nav-tree-item-content'>
                <span>{element.title}</span>
                <img src='/assets/images/play.png' alt='go to link' />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default NavTree;
