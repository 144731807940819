import { Routes, Route } from 'react-router-dom';

import './level-container.scss';
import Intro from '../levels/intro';
import ThePond from '../levels/the-pond';
import TheRiverOne from '../levels/the-river-one';
import DualLightOne from '../levels/dual-light-one';
import DualLightTwo from '../levels/dual-light-two';
import TheRiverTwo from '../levels/the-river-two';
import InLandscape from '../levels/in-landscape';
import ForeverYoung from '../levels/forever-young';
import CrazyKindHope from '../levels/crazy-kind-hope';
import PrivateRoute from '../route/PrivateRoute';
import EmptinessOcean from '../levels/emptiness-ocean';
import RiverBeyond from '../levels/river-beyond';
import GeneralLayout from '../layout/GeneralLayout';
// import WhereToNext from '../levels/where-to-next';
import TheEnd from '../levels/the-end';

const LevelContainer = () => {
  return (
    <div className='level-container'>
      <GeneralLayout>
        <Routes>
          <Route exact path='/' element={<Intro />} />
          <Route
            exact
            path='/the-pond'
            element={
              <PrivateRoute>
                <ThePond />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/the-river'
            element={
              <PrivateRoute>
                <TheRiverOne />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/dual-light-leaving'
            element={
              <PrivateRoute>
                <DualLightOne />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/a-river-between'
            element={
              <PrivateRoute>
                <TheRiverTwo />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/dual-light-letting-go'
            element={
              <PrivateRoute>
                <DualLightTwo />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/pond-reflection'
            element={
              <PrivateRoute>
                <EmptinessOcean />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/in-a-landscape'
            element={
              <PrivateRoute>
                <InLandscape />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/forever-young'
            element={
              <PrivateRoute>
                <ForeverYoung />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/a-crazy-kind-of-hope'
            element={
              <PrivateRoute>
                <CrazyKindHope />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/a-river-beyond'
            element={
              <PrivateRoute>
                <RiverBeyond />
              </PrivateRoute>
            }
          />
          {/* <Route
            exact
            path='/where-to-next'
            element={
              <PrivateRoute>
                <WhereToNext />
              </PrivateRoute>
            }
          /> */}
          <Route
            exact
            path='/the-end'
            element={
              <PrivateRoute>
                <TheEnd />
              </PrivateRoute>
            }
          />
        </Routes>
      </GeneralLayout>
    </div>
  );
};
export default LevelContainer;
