import VideoPlayer from '../common/VideoPlayer';
import './dual-light-one.scss';

const DualLightOne = () => {
  return (
    <>
      <VideoPlayer />
    </>
  );
};
export default DualLightOne;
