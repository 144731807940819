import React, { useContext, useEffect, useState } from 'react';
import gsap from 'gsap';
import Sketch from 'react-p5';

import './the-river-one.scss';
import { setNextLevel, setSoundSrc } from '../../state/MainActions';
import { MainContext } from '../../state/MainContext';
import Particle from './particle';

const TheRiverOne = () => {
  const [state, dispatch] = useContext(MainContext);
  const [width] = useState(window.innerWidth);
  const [height] = useState(window.innerHeight);
  const [rows, setRows] = useState(0);
  const [cols, setCols] = useState(0);
  const [p, setP] = useState();
  const [flowfield, setFlowField] = useState();
  const [flowcolorfield, setColorField] = useState();
  const [particles, setParticles] = useState([]);

  const inc = 0.1;
  const incStart = 0.005;
  const magInc = 0.0005;
  const scl = 10;
  const numParticles = 100;
  const subtitles_width = 510;
  const subtitles_height = 70;
  let start = 0;
  let zoff = 0;
  let magOff = 0;
  let showField = false;

  useEffect(() => {
    // dispatch(setSoundSrc(`/assets/sounds/river.mp3`));
  }, []);

  useEffect(() => {
    if (p) {
      setCols(Math.floor(width / scl));
      setRows(Math.floor(height / scl));
      const pr = [];
      for (let i = 0; i < numParticles; i++) {
        pr[i] = new Particle(p, cols, scl, width, height, -1, -1);
      }
      setParticles(pr);

      setFlowField(new Array(rows * cols));
      setColorField(new Array(rows * cols));
    }
  }, [p]);

  const setup = (p5, canvasParentRef) => {
    p5.pixelDensity(1);
    p5.createCanvas(width, height).parent(canvasParentRef);
    if (!p) {
      setP(p5);
    }
    p5.background(0);
    const video = p5.createVideo('assets/videos/subtitles.mp4');
    video.showControls();
    video.size(subtitles_width, subtitles_height);
    video.position(width/2 - subtitles_width/2, height - subtitles_height);
    video.style("position:absolute; z-index: 1");
    video.autoplay();
    video.noLoop();
    video.hideControls();
  };

  const draw = (p5) => {
    if (!particles || !flowfield) return;
    if (showField) {
      p5.background(0);
    } else {
      p5.background(p5.color(0, 0, 0, 5));
    }

    let yoff = start;
    const ff = flowfield;
    const fcf = flowcolorfield;
    for (let y = 0; y < rows; y++) {
      let xoff = start;
      for (let x = 0; x < cols; x++) {
        let index = x + y * cols;
        let r = p5.noise(xoff, yoff, zoff) * 255;
        let g = p5.noise(xoff + 100, yoff + 100, zoff) * 255;
        let b = p5.noise(xoff + 200, yoff + 200, zoff) * 255;
        let angle = p5.noise(xoff, yoff, zoff) * Math.PI * 2;
        let v = p5.constructor.Vector.fromAngle(angle); // vector from angle
        let m = map(p5.noise(xoff, yoff, magOff), 0, 1, -5, 5);
        v.setMag(m);
        if (showField) {
          p5.push();
          p5.stroke(255);
          p5.translate(x * scl, y * scl);
          p5.rotate(v.heading());
          let endpoint = Math.abs(m) * scl;
          p5.line(0, 0, endpoint, 0);
          if (m < 0) {
            p5.stroke('red');
          } else {
            p5.stroke('green');
          }
          p5.line(endpoint - 2, 0, endpoint, 0);
          p5.pop();
        }
        ff[index] = v;

        fcf[index] = [r, g, b];
        xoff += inc;
      }
      yoff += inc;
    }
    setFlowField(ff);
    setColorField(fcf);
    magOff += magInc;
    zoff += incStart;
    start -= magInc;

    if (!showField) {
      const pr = [];
      for (let i = 0; i < particles.length; i++) {
        pr[i] = particles[i];
        pr[i].follow(ff, fcf);
        pr[i].update();
        pr[i].edges();
        pr[i].show();
      }

      if (Math.floor(Math.random() * 10) > 5 && particles.length < 2500) {
        let rnd = Math.floor(p5.noise(zoff) * 20);
        for (let i = 0; i < rnd; i++) {
          pr.push(new Particle(p5, cols, scl, width, height, -1, -1));
        }
      } else if (particles.length > 2000) {
        let rnd = Math.floor(Math.random() * 10);
        for (let i = 0; i < rnd; i++) {
          pr.shift();
        }
      }
      setParticles(pr);
    }
  };
  const onMousePressed = (p5) => {
    const pr = particles;
    for (let i = 0; i < 20; i++) {
      pr.push(new Particle(p5, cols, scl, width, height, p5.mouseX, p5.mouseY));
    }
    setParticles(pr);
  };
  return (
    <div className='river-one-container'>
      <Sketch setup={setup} draw={draw} mouseDragged={onMousePressed} />
    </div>
  );
};

const map = (value, a, b, c, d) => {
  // first map value from (a..b) to (0..1)
  value = (value - a) / (b - a);
  // then map it from (0..1) to (c..d) and return it
  return c + value * (d - c);
};

export default TheRiverOne;
