import React, { useContext } from 'react';
import { MainContext } from '../../state/MainContext';
import { Navigate, Route } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  let [state] = useContext(MainContext);
  if (!state.isAuth) {
    return <Navigate to='/' replace />;
  }
  return children;
};

export default PrivateRoute;
