import { useContext, useEffect, useState } from 'react';
import gsap, { Expo } from 'gsap';

import { toggleNavigation } from '../../state/MainActions';
import { MainContext } from '../../state/MainContext';
import './nav-container.scss';
import NavPlanes from './navigation/nav-planes';
import NavTree from './navigation/nav-tree';
import { useLocation } from 'react-router-dom';

const NavContainer = () => {
  const location = useLocation()
  const [state, dispatch] = useContext(MainContext);
  const [hovered, setHovered] = useState(-1);

  useEffect(() => {
    gsap.to('.nav-container', {
      autoAlpha: 0,
      duration: 0,
    });
  }, []);
  useEffect(() => {
    if (state.transitionPlaying) {
      gsap.to('.nav-container', {
        autoAlpha: 0,
        duration: 0,
        ease: Expo.easeInOut,
      });
    } else {
      gsap.to('.nav-container', {
        autoAlpha: 1,
        duration: 0,
        ease: Expo.easeIn,
      });
    }
  }, [state.transitionPlaying]);

  return (
    <div className='nav-container' 
        data-expanded={state.nav.expanded}
        data-is-video={location.pathname !== '/the-pond' && location.pathname !== '/the-river'} >
      <div
        className='nav-container-icon'
        onClick={() => {
          dispatch(toggleNavigation(true));
        }}
      >
        <img src='/assets/images/layers1.png' alt='Fire Horse & Shadow BG' className='nav-container-icon-retract' />
        <img src='/assets/images/layers2.png' alt='Fire Horse & Shadow BG' className='nav-container-icon-expand' />
      </div>
      <div
        className='nav-container-close'
        onClick={() => {
          setHovered(-1);
          dispatch(toggleNavigation(false));
        }}
      >
        <img src='/assets/images/close.png' alt='Fire Horse & Shadow BG' className='nav-container-icon-close' />
      </div>
      {state.nav.expanded && (
        <div className='navTreeContainer'>
          <NavPlanes hovered={hovered} />
          <NavTree setHovered={setHovered} />
        </div>
      )}
    </div>
  );
};

export default NavContainer;
