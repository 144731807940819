import VideoPlayer from '../common/VideoPlayer';
import './emptiness-ocean.scss';

const EmptinessOcean = () => {
  return (
    <>
      <VideoPlayer />
    </>
  );
};
export default EmptinessOcean;
