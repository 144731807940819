import VideoPlayer from '../common/VideoPlayer';
import './river-beyond.scss';

const RiverBeyond = () => {
  return (
    <>
      <VideoPlayer />
    </>
  );
};
export default RiverBeyond;
