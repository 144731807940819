import { defaultMainState } from './MainContext';
import { cloneDeep } from 'lodash';

export const RESET_STATE = 'RESET_STATE';
export const ENTER_SITE_CLICKED = 'AUTHORIZE';
export const SET_SYSTEM_SPECS = 'SET_SYSTEM_SPECS';
export const TOGGLE_NAVIGATION = 'TOGGLE_NAVIGATION';
export const TOGGLE_ABOUT = 'TOGGLE_ABOUT';
export const SET_CURRENT_LEVEL = 'SET_CURRENT_LEVEL';
export const SET_NEXT_LEVEL = 'SET_NEXT_LEVEL';
export const SET_TRANSITION_PLAYING = 'SET_TRANSITION_PLAYING';
export const ALLOW_TRANSITION = 'ALLOW_TRANSITION';
export const SET_SOUND_SRC = 'SET_SOUND_SRC';
export const SET_INTERACTION_SRC = 'SET_INTERACTION_SRC';
export const ENABLE_SCROLL_TO_NEXT = 'ENABLE_SCROLL_TO_NEXT';

export const mainReducer = (state, action) => {
  let newState = {
    ...state,
  };
  switch (action.type) {
    case SET_SYSTEM_SPECS:
      newState.system = action.payload.system;
      return newState;

    case ENTER_SITE_CLICKED:
      newState.isAuth = true;
      newState.audioSrc = '/assets/sounds/ambience.mp3';
      newState.nextLevel = 0;
      newState.hoveredBranch = -1;
      return newState;

    case RESET_STATE:
      const { isAuth, system } = newState;
      newState = cloneDeep(defaultMainState);
      newState.isAuth = isAuth;
      newState.system = system;
      return newState;

    case TOGGLE_NAVIGATION:
      newState.nav.expanded = action.payload.expanded;
      return newState;

    case ENABLE_SCROLL_TO_NEXT:
      newState.scrollToNext = action.payload.enable;
      return newState;

    case TOGGLE_ABOUT:
      newState.about.expanded = action.payload.expanded;
      return newState;

    case SET_CURRENT_LEVEL:
      newState.currentLevel = action.payload.level;
      newState.hoveredBranch = -1;
      return newState;

    case SET_NEXT_LEVEL:
      newState.nextLevel = action.payload.level;
      newState.hoveredBranch = -1;

      return newState;

    case SET_TRANSITION_PLAYING:
      newState.transitionPlaying = action.payload.playing;
      return newState;

    case ALLOW_TRANSITION:
      newState.playTransition = action.payload.allow;
      return newState;

    case SET_SOUND_SRC:
      newState.audioSrc = action.payload.src;
      return newState;

    case SET_INTERACTION_SRC:
      newState.interactionSrc = action.payload.src;
      return newState;

    default:
      return state;
  }
};
