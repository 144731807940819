import React, { useReducer, useEffect, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';
import gsap from 'gsap';

import { cloneDeep } from 'lodash';

import './App.scss';
import LevelContainer from './components/containers/level-container';
import TransitionContainer from './components/containers/transition-container';

import { defaultMainState, MainContext } from './state/MainContext';
import { mainReducer } from './state/MainReducer';
import { setSystemSpecs } from './state/MainActions';

const App = () => {
  const [state, dispatch] = useReducer(mainReducer, cloneDeep(defaultMainState));

  const audio = useRef();
  const interactions = useRef();

  useEffect(() => {
    const system = {};
    system.isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
    system.iOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window.MSStream

    dispatch(setSystemSpecs(system));
  }, []);

  useEffect(() => {
    if (audio && audio.current && audio.current.audioEl) {
      gsap.killTweensOf(audio.current.audioEl.current);
      gsap.fromTo(audio.current.audioEl.current, { volume: 0 }, { volume: 0.5, duration: 1 });
    }
  }, [state.audioSrc]);

  useEffect(() => {
    if (interactions && interactions.current && interactions.current.audioEl) {
      interactions.current.audioEl.current.play();
    }
  }, [state.interactionSrc]);

  useEffect(() => {
    if (audio && audio.current && audio.current.audioEl && state.currentLevel < 1) {
      gsap.killTweensOf(audio.current.audioEl.current);
      if (state.transitionPlaying) {
        if (state.system.iOS) {
          audio.current.audioEl.current.pause();
        } else {
          audio.current.audioEl.current.volume = 0.5;
          setTimeout(() => {
            audio.current.audioEl.current.volume = 0.5;
            gsap.to(audio.current.audioEl.current, { volume: 0, duration: 1 });
          }, 500);
        }
      } else {
        if (state.system.iOS) {
          audio.current.audioEl.current.play();
        } else {
          setTimeout(() => {
            audio.current.audioEl.current.volume = 0;
            gsap.to(audio.current.audioEl.current, { volume: 0.5, duration: 1 });
          }, 500);
        }
      }
    }
  }, [state.transitionPlaying, state.currentLevel, state.system.iOS]);

  const onAudioEnded = (e) => {
    // console.log('Audio Ended', state.currentLevel);
    if (state.levels[state.currentLevel].slug === '/the-pond') {
      audio.current.audioEl.current.currentTime = 0;
      audio.current.audioEl.current.play();
    } else if (state.levels[state.currentLevel].slug === '/the-river') {
    }
  };

  return (
    <MainContext.Provider value={[state, dispatch]}>
      <Router>
        <ReactAudioPlayer ref={interactions} src={state.interactionSrc} autoPlay volume={1} />
        <ReactAudioPlayer ref={audio} src={state.audioSrc} autoPlay volume={0.5} onEnded={onAudioEnded} />

        <div className='App'>
          <LevelContainer />
          <TransitionContainer />
        </div>
      </Router>
    </MainContext.Provider>
  );
};

export default App;
