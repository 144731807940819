import VideoPlayer from '../common/VideoPlayer';
import './dual-light-two.scss';

const DualLightTwo = () => {
  return (
    <>
      <VideoPlayer />
    </>
  );
};
export default DualLightTwo;
