import { useContext, useEffect, useRef, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { setCurrentLevel, setTransitionPlaying, allowTransition } from '../../state/MainActions';
import { MainContext } from '../../state/MainContext';
import './transition-container.scss';

const TransitionContainer = () => {
  let location = useLocation();
  const vidRef = useRef(null);
  const [state, dispatch] = useContext(MainContext);
  const [containerStyle, setContainerStyle] = useState('transition-container transition-container-hidden');
  const [videoSource, setVideoSource] = useState('');
  const [redirection, setRedirection] = useState('');

  const onTransitionEnded = () => {
    dispatch(setTransitionPlaying(false));
    dispatch(allowTransition(false));
    setContainerStyle('transition-container transition-container-hidden');
    if (state.currentLevel !== state.nextLevel) {
      const nextLevel = state.levels[state.nextLevel];
      setRedirection(nextLevel.slug);
    }
  };
  useEffect(() => {
    // console.log(
    //   'USE_EFFECT(NEXT_LEVEL)',
    //   state.transitionPlaying,
    //   state.currentLevel,
    //   state.nextLevel,
    //   state.levels.length,
    //   state.playTransition
    // );
    if (state.currentLevel + 1 === state.levels.length -1) {
      // console.log('THIS IS THE FINAL LEVEL. GO BACK TO THE HOME SCREEN')
      const nextLevel = state.levels[0];
      setRedirection(nextLevel.slug);
    } else {
      // if (state.playTransition) {
      //   if (!state.transitionPlaying && state.nextLevel >= 0 && state.currentLevel >= 0) {
      //     if (state.currentLevel !== state.nextLevel) {
      //       console.log('PLAY TRANSITION OUT for current level', state.currentLevel);
      //       setVideoSource(state.levels[state.currentLevel].transition);
      //       dispatch(setTransitionPlaying(true));
      //       setContainerStyle('transition-container transition-container-visible');
      //     }
      //   }
      // } else {
        if (state.currentLevel !== state.nextLevel) {
          const nextLevel = state.levels[state.nextLevel];
          setRedirection(nextLevel.slug);
        }
      // }
    }
  }, [state.nextLevel]);

  useEffect(() => {
    if (state.transitionPlaying) {
      vidRef.current.play();
    }
  }, [state.transitionPlaying]);

  useEffect(() => {
    if (redirection !== '') {
      setRedirection('');
    }
  }, [redirection]);

  useEffect(() => {
    for (let i = 0; i < state.levels.length; i++) {
      if (location.pathname === state.levels[i].slug) {
        dispatch(setCurrentLevel(i));
      }
    }
  }, [location]);

  return (
    <div className={containerStyle}>
      {redirection !== '' && <Navigate push to={redirection} />}
      {state.transitionPlaying && videoSource !== '' && (
          <video ref={vidRef} preload='auto' onEnded={() => onTransitionEnded()}>
            <source src={videoSource} type='video/mp4' />
          </video>
      )}
    </div>
  );
};

export default TransitionContainer;
