import {
  RESET_STATE,
  ENTER_SITE_CLICKED,
  SET_SYSTEM_SPECS,
  TOGGLE_NAVIGATION,
  TOGGLE_ABOUT,
  SET_CURRENT_LEVEL,
  SET_NEXT_LEVEL,
  SET_TRANSITION_PLAYING,
  ALLOW_TRANSITION,
  SET_SOUND_SRC,
  SET_INTERACTION_SRC,
  ENABLE_SCROLL_TO_NEXT,
} from './MainReducer';

export const resetState = () => ({
  type: RESET_STATE,
  payload: {},
});

export const setSystemSpecs = (value) => ({
  type: SET_SYSTEM_SPECS,
  payload: { system: value },
});

export const enterSiteClicked = (value) => ({
  type: ENTER_SITE_CLICKED,
  payload: { system: value },
});

export const toggleNavigation = (value) => ({
  type: TOGGLE_NAVIGATION,
  payload: { expanded: value },
});

export const toggleAbout = (value) => ({
  type: TOGGLE_ABOUT,
  payload: { expanded: value },
});

export const setCurrentLevel = (value) => ({
  type: SET_CURRENT_LEVEL,
  payload: { level: value },
});

export const setNextLevel = (value) => ({
  type: SET_NEXT_LEVEL,
  payload: { level: value },
});

export const setTransitionPlaying = (value) => ({
  type: SET_TRANSITION_PLAYING,
  payload: { playing: value },
});

export const allowTransition = (value) => ({
  type: ALLOW_TRANSITION,
  payload: { allow: value },
});

export const setSoundSrc = (value) => ({
  type: SET_SOUND_SRC,
  payload: { src: value },
});

export const setInteractionSrc = (value) => ({
  type: SET_INTERACTION_SRC,
  payload: { src: value },
});
