import React from 'react';

export const defaultMainState = {
  system: {
    iOS: null,
    isSafari: null,
  },
  audioSrc: '',
  interactionSrc: '',
  isAuth: false,
  isLocked: false,
  nav: {
    expanded: false,
  },
  about: {
    expanded: false,
  },
  scrollToNext: false,
  transitionPlaying: false,
  currentLevel: -1,
  nextLevel: -1,
  playTransition: false,
  levels: [
    {
      title: 'The Pond',
      slug: '/the-pond',
      transition: 'https://allofourdreaming.s3.amazonaws.com/trans01-CrazyKindHope.m4v',
      show: true,
      next: '/a-crazy-kind-of-hope',
    },
    {
      title: 'A Crazy Kind of Hope',
      slug: '/a-crazy-kind-of-hope',
      video: 'https://vimeo.com/912395585/60f57d1266',
      transition: 'https://allofourdreaming.s3.amazonaws.com/trans02-ForeverYoung.mp4',
      show: true,
      next: '/forever-young',
    },
    {
      title: 'Forever Young',
      slug: '/forever-young',
      video: 'https://vimeo.com/912399320/b678ab910c',
      transition: 'https://allofourdreaming.s3.amazonaws.com/trans03-InALandscape.mp4',
      show: true,
      next: '/in-a-landscape',
    },
    {
      title: 'In A Landscape',
      slug: '/in-a-landscape',
      video: 'https://vimeo.com/912403083/682bdf703d',
      transition: 'https://allofourdreaming.s3.amazonaws.com/trans04-TheRiver.mp4',
      show: true,
      next: '/the-river',
    },
    {
      title: 'The River',
      slug: '/the-river',
      transition: 'https://allofourdreaming.s3.amazonaws.com/trans05-DualLightLeaving.mp4',
      show: true,
      next: '/dual-light-leaving',
    },
    {
      title: 'Dual Light: Leaving',
      slug: '/dual-light-leaving',
      video: 'https://vimeo.com/912406509/e8ca84c982',
      transition: 'https://allofourdreaming.s3.amazonaws.com/trans06-ARiverBetween.mp4',
      show: true,
      next: '/a-river-between',
    },
    {
      title: 'A River Between',
      slug: '/a-river-between',
      video: 'https://vimeo.com/912411329/d43304ce48',
      transition: 'https://allofourdreaming.s3.amazonaws.com/trans07-DualLightLettingGo.mp4',
      show: true,
      next: '/dual-light-letting-go',
    },
    {
      title: 'Dual Light: Letting Go',
      slug: '/dual-light-letting-go',
      video: 'https://vimeo.com/915265870/eee0c21245',
      transition: 'https://allofourdreaming.s3.amazonaws.com/trans08-ARiverBeyond.mp4',
      show: true,
      next: '/a-river-beyond',
    },
    {
      title: 'A River Beyond',
      slug: '/a-river-beyond',
      video: 'https://vimeo.com/912416659/8674526acc',
      transition: 'https://allofourdreaming.s3.amazonaws.com/transition-09.mp4',
      show: true,
      next: '/the-end',
    },
    {
      title: 'The End',
      slug: '/the-end',
      show: false,
      video: 'https://vimeo.com/873329356/0513749ff1'
    },
    {
      title: 'Pond Reflection',
      slug: '/pond-reflection',
      video: 'https://vimeo.com/801441473',
      transition: 'https://allofourdreaming.s3.amazonaws.com/transition-05.mp4',
      show: false,
      next: '/the-river',
    },
  ],
};
export const MainContext = React.createContext(null);
export const CanvasContext = React.createContext(null);
