import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { toggleAbout } from '../../state/MainActions';
import { MainContext } from '../../state/MainContext';
import './about-container.scss';

const AboutContainer = () => {
  const [state, dispatch] = useContext(MainContext);
  const location = useLocation()

  return (
    <div className='about-container--wrapper' 
        data-expanded={state.about.expanded} 
        data-is-video={location.pathname !== '/the-pond' && location.pathname !== '/the-river'}>
      <div className='about-container--content'>
        <div
          className='about-container--close'
          onClick={() => {
            dispatch(toggleAbout(false));
          }}
        >
          <img src='/assets/images/close.png' alt='Close About Page' />
        </div>
        <div className='about-container--content-wrap'>
          <div className='about-container--title'>
            <img src='/assets/images/about-title.png' alt='All of our dreaming' />
          </div>
          <div className='about-container--sub-title'>a web performance</div>
          <div className='about-container--copy'>
            A collection of autobiographical embodied stories honouring dreaming, changing and letting go. The audience
            enters a watery space, The Pond, whose surface reflects an endlessly open sky while below everything is in
            process and development.
            <br />
            <br />
            We descend to The River and are introduced to Richard Nann, Andrea’s father, speaking of a dream he has had, a vision.
            <br />
            <br />
            There is quietness and reflection. Time feels both brief and infinite.
            <br />
            <br />
            We continue, meeting each artist at their most open and tender, and journey with them as they navigate a
            radically changed world. Everyone is in a process of transformation and letting go; making space for
            something new.
            <br />
            <br />
            Encounters with nature, kinship, wonderment, humour, loss, sorrow, grace.
            <br />
            <br />
            <br />
            <b>All of Our Dreaming is co-created by:</b>
            <br />
            <br />
            Performers: Andrea Nann, Yuichiro Inoue, Naishi Wang, Andy Maize
            <br />
            Choreographers: Peggy Baker, Sarah Chase, Andrea Nann
            <br />
            Dramaturge: Sarah Chase
            <br />
            Creative contributor: Brendan Wyatt
            <br />
            Web experience design: Elysha Poirier & Omar Faleh
            <br />
            Directors and editors of film content: Henry Mak, Yasuhiro Okada
            <br />
            Directors of photography: Eric Chan, Daniel Loan
            <br />
            Assistant camera: Sophia Mai Wolfe
            <br />
            Sound designers/composers: Joshua Van Tassel, Sarah Chase, Antoine Bedard
            <br />
            Additional music (A Crazy Kind of Hope) by: Justin Rutledge
            <br />
            Production lighting designers: Noah Feaver, James Proudfoot, Eric Chan
            <br />
            Costume and set designer (In a Landscape): Cheryl Lalonde
            <br />
            Pond photography: Sarah Chase
            <br />
            River conversations: Richard Nann with Andrea Nann
            <br />
            Technical director: A.J. Morra
            <br />
            Particle systems for ‘A River Beyond’: Jérôme Delapierre
            <br />
            <br />
            <br />
            <b>A Crazy Kind of Hope</b>
            <br />
            <br />
            Conceived, directed, choreographed by Sarah Chase
            <br />
            In creation with performer Andrea Nann
            <br />
            Lighting design: James Proudfoot
            <br />
            Sound design: Sarah Chase
            <br />
            Drone: Antoine Bedard
            <br />
            Guitar: Justin Rutledge
            <br />
            Director of photography: Daniel Loan
            <br />
            Camera operator and editor: Yasuhiro Okada
            <br />
            Assistant camera: Sophia Mai Wolfe
            <br />
            Firehall Arts Centre technician: Jethro Allen-Martin
            <br />
            Production manager: A.J. Morra
            <br />
            <br />
            <br />
            <b>Dual Light: Yuichiro and Naishi</b>
            <br />
            <br />
            Conceived and directed by choreographer Andrea Nann
            <br />
            In creation with performers Yuichiro Inoue, and Naishi Wang and creative collaborator Brendan Wyatt
            <br />
            Dramaturgy and story development by Sarah Chase
            <br />
            Lighting design: Noah Feaver
            <br />
            Film director, editor, camera: Henry Mak
            <br />
            Director of photography: Eric Chan
            <br />
            Production manager: A.J. Morra
            <br />
            <br />
            <br />
            <b>In a Landscape</b>
            <br />
            <br />
            Choreographer Peggy Baker
            <br />
            Performer: Andrea Nann
            <br />
            Music by John Cage, performed by Stephen Drury
            <br />
            Film director, editor, camera: Henry Mak
            <br />
            Director of photography: Eric Chan
            <br />
            Costume and set design by Cheryl Lalonde
            <br />
            Production manager: A.J. Morra
            <br />
            <br />
            <br />
            <b>Forever Young</b>
            <br />
            <br />
            Singer/songwriter: Andy Maize
            <br />
            Composer: Bob Dylan
            <br />
            Camera Henry Mak
            <br />
            Camera: Eric Chan
            <br />
            Animation: Elysha Poirier
            <br />
            Production manager: A.J. Morra
            <br />
            <br />
            <br />
            <b>River(s)</b>
            <br />
            <br />
            Conversations between Andrea Nann and her father Richard Nann
            <br />
            Photos by Sarah Chase and animation by Elysha Poirier for 'A River Between'
            <br />
            Particle systems for ‘A River Beyond’: Jérôme Delapierre
            <br />
            <br />
            Dual Light, In a Landscape and Forever Young were filmed in 2022 at Pia Bouman School of Dance and Array
            Space in Tkaronto on the traditional territories of many nations including the Mississaugas of the Credit,
            the Anishnabeg, the Chippewa, the Haudenosaunee and the Wendat peoples and is now home to many diverse First
            Nations, Inuit and Métis peoples.
            <br />
            <br />
            A Crazy Kind of Hope was filmed in 2022 at Firehall Arts Centre located on the shared, unceded, ancestral
            territories of the xʷməθkʷəy̓əm (Musqueam), Sḵwx̱wú7mesh (Squamish), and səlilwətaɬ (Tsleil-Waututh) Nations
            <br />
            <br />
            All of Our Dreaming is made possible with generous support from the Canada Council for the Arts - Digital
            Now program.

            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Elysha.jpg' alt='Elysha Poirier' />
              </div>
              <div className='about-container--bio-text'>
                Elysha Poirier - web experience design & animation<br />
                Elysha began her career in graphic design before transitioning to animation for short films and, subsequently, for the stage. Combining digital and analog sources she creates intrinsic worlds of their own reflected in mixed media and digital 3D environments. Elysha's work is deeply connected to movement and sound where she translates audio and gesture into real-time motion graphics. She's realised a wide range of installations and engaged in live performances for dance, experimental music, theatre and web. Elysha is currently working as an animator and developer for interactive experiences in Tiohtià:ke Montréal. Elysha has also been a core-collaborator with Dreamwalker Dance Company. Recent works with the company include 'Firehorse and Shadow' where  her role was Web & User Experience Designer. <a href='https://www.elyshapoirier.com' target='_blank' rel='noreferrer'>www.elyshapoirier.com</a>
              </div>
            </div>

            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Omar.jpg' alt='Omar Al Faleh' />
              </div>
              <div className='about-container--bio-text'>
                Omar Al Faleh - web experience design<br />
                Omar is an interactive media designer and developer who lives between New York, USA and Montreal, Canada. With a background in architecture, arts, and computer science, Omar’s work is focused on designing playful, engaging, performative, and immersive media spaces where interaction is neither scripted nor assumed, and media is constantly reinterpreting the definitions of space and body. His work ranges from building interactive installations, to media scenography for theatre and dance, and media design for interactive exhibition spaces.<br />
                Omar's work was displayed in Montreal, New York, and The Netherlands, and his research work affiliation includes New York University, Concordia University, Hexagram and Arizona University. <a href='https://morscad.com' target='_blank' rel='noreferrer'>www.morscad.com</a>
              </div>
            </div>

            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Andrea.jpg' alt='Andrea Nann' />
              </div>
              <div className='about-container--bio-text'>
                Andrea Nann<br />
                Andrea is a contemporary dance artist, producer, curator, facilitator, instigator, community mobilizer,  consultant/mentor, and founding artistic director of Dreamwalker. She creates work for the stage,  film and outdoor sites; investigating contemporary approaches to creation through collaboration with  individuals from all artistic disciplines. Andrea dances to reach across distance, to experience others  in celebration of possibility, diversity, connection and belonging; believing that dance can shift  attitudes and ways of being, tuning us into what makes each of us distinct, to what we share, and  ultimately how we can live together in wonderment and peace. A graduate of York University’s  Department of Fine Arts, Andrea was a member of the Danny Grossman Dance Company for 15  years where she created, performed and taught major roles from the works of Mr. Grossman and  guest choreographers. In 2016 Ontario Contact recognized Andrea as Artist of the Year for  contributions to the Performing Arts touring sector in Ontario, and in 2019 she received a Dora  Mavor Moore Award for Outstanding Performance by an Individual in Dance. 
              </div>
            </div>

            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Sarah.jpg' alt='Sarah Chase' />
              </div>
              <div className='about-container--bio-text'>
                Sarah Chase - Choreographer A Crazy Kind of Hope/dramaturge/pond photographer<br />  
                Sarah has performed her dancestory work across Canada and Europe. She also toured with both  Benôit Lachambre’s Dance par B. Lieux, and with German choreographer Raimund Hoghe. Her many  works created for Canadian artists include Toronto Dance Theatre, Peggy Baker, Andrea Nann,  Theatre Replacement, AntonijaLivingstone, Montreal Danse, and Marc Boivin. She is the recipient of  the Jacqueline Lemieux Award for Excellence from the Canada Council for the Arts, and received the  Prize of the Festival at the Munich Dance Biennale. The solos she created for both Peggy Baker, and Andrea Nann won Doras. She is an associate dance artist of the Canadian National Arts Centre.  Sarah is a core-collaborator with Dreamwalker Dance Company since 2006 and was creative contributor and director for Firehorse and Shadow. 
              </div>
            </div>

            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Peggy.jpg' alt='Peggy Baker' />
              </div>
              <div className='about-container--bio-text'>
                Peggy Baker - Choreographer In a Landscape<br />
                Peggy Baker has been a vibrant presence in dance for fully five decades, known and respected as a performer in the work of esteemed Canadian and American creators; as an educator in conservatory dance programs throughout North America; as a champion of live music for dance; and as a choreographer of poetic and visually striking works for her Toronto based company, Peggy Baker Dance Projects (1990 – 2023). Her work has been presented in theatres, galleries, public spaces and on media screens across Canada and the U.S., as well as Mexico, Argentina, Belgium, Germany, the Netherlands, Denmark, Korea, and Japan. Among her many honours: the Order of Canada, the Governor General’s Award, the Premier’s Award, the Carsen Prize, Honorary Doctorates from York University and the University of Calgary, and six Dora Mavor Moore awards. She is a 2023 inductee into the Dance Collection Danse Hall of Fame. Ms. Baker continues  her involvement in the dance milieu through teaching, mentorship, and co-creation projects with new generation artists.
              </div>
            </div>


            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Yuichrio.jpg' alt='Yuichiro Inoue' />
              </div>
              <div className='about-container--bio-text'>
                Yuichiro Inoue - performer/co-creator Dual Light  <br />
                Yuichiro Inoue – Dancer/ Choreographer / Teacher <br />
                Currently working in his 17th year with the Toronto Dance Theatre. Born in Japan, Yuichiro moved to Germany at the age of 15 to study at the John Cranko Ballet School in Stuttgart. He spent nine years as a company member of German Staatstheater Braunschweig, Badisches Staatstheater Karlsruhe, and Saarlaendisches Statts Theatre in Saarbruecken before joining TDT. He has performed in USA, London, and throughout Germany, France, Italy, Switzerland, the Netherlands, Austria, Croatia, China, Kyiv, Mexico, Columbia, Japan and Canada. Yuichrio completed the Teacher Training Program and RAD certification at The National Ballet School of Canada and has taught at many of Canada and Japan’s foremost dance institutions. His students have gone on to be accepted into prestigious dance companies and post-secondary institutions. A co-founder of 3+ Dance Collective, as a choreographer, his works have included commissions for award-winning film, international festivals and competitions. A nominee of multiple Dora Awards and a Johanna Metcalf Performing Arts Award.
              </div>
            </div>


            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Naishi.jpg' alt='Naishi Wang' />
              </div>
              <div className='about-container--bio-text'>
              Naishi Wang - performer/co-creator Dual Light <br />
              Based in culturally diverse Toronto and hailing from Changchun, China, Naishi Wang delves deep into the motivations underlying the body's movements and the emotions it articulates. Renowned for his extraordinary improvisations, he transforms these into tangible embodiments of meaning, a skill mirrored in his practice as a visual artist. Wang's drawings, akin to dances on paper, resonate with the essence of his dance work.<br />
              A part of the esteemed MAI (Montréal, arts interculturels) program in February 2019, Wang's solo piece, "Taking Breath," unveiled his fascination with intimate forms of bodily communication—an intrigue revisited in the duet, "Face To Face." This duet sheds light on our contemporary virtual communication modes and the intricate factors that collaborate to convey intentions, even within the simplest exchanges.<br />
              Presently, Naishi collaborates with UK-based artist Jean Abreu on "Deciphers" and a quintet titled "Eyes Wide Open." He holds the esteemed position of artist in residency at the Citadel, Harbourfront Centre, and TO Live. Recognitions adorn his path, including Les Respirations du FTA (2021), Small Scale Creation Fund from CanDance (2021), and the Chalmers Arts Fellowship from Ontario Arts Council (2022). Naishi Wang's artistic journey epitomizes innovation and cultural exploration.<br />
              <a href='https://naishi.dance/' target='_blank' rel='noreferrer'>https://naishi.dance/</a>
              </div>
            </div>

                       
            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Brendan.jpg' alt='Brendan Wyatt' />
              </div>
              <div className='about-container--bio-text'>
                Brendan Wyatt - creative contributor Dual Light  <br />
                Brendan is a multi nominated and award-receiving artist in dance and choreography and has over 35  years of experience in classical, contemporary and experimental dance. He performed nationally  and internationally, facilitated and instructed dance workshops for several schools and companies, and created and realized roles in over 200 individual works. Brendan has acted and choreographed  for film including starring in the tiff world premiere of Father and choreographing the finale sequence  in the film Crazy House. Brendan is inspired by art, nature, music and sound, their ability to fill one’s  life with purpose and carve out a place of resonating in the soul. He is a core-collaborator with  Dreamwalker Dance Company and joined the organization in 2008. 
              </div>
            </div>


            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Andy.jpg' alt='Andy Maize' />
              </div>
              <div className='about-container--bio-text'>
                Andy Maize – performer/singer-songwriter<br />
                Andy Maize is a singer-songwriter and co-founder of Canadian roots/rock band Skydiggers. The band  has released 20 albums over the course of a thirty-five year career, including their debut Skydiggers  (1990), and more recently Hide Your Light / Bide Your Time (2023). Andy has also released two solo albums as well as a collaboration in 2020 of “Townies (an audio play)” with Michael Timmins of the Cowboy Junkies. 
              </div>
            </div>

            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Joshua.jpg' alt='Joshua Van Tassel' />
              </div>
              <div className='about-container--bio-text'>
                Joshua Van Tassel – sound design All of Our Dreaming, composer Dual Light, < br />
                Joshua Van Tassel is a Juno and ECMA winning drummer, producer, and composer. He works and records with some of Canada's best song writers such as Donovan Woods, Bahamas, Great Lake Swimmers, Rose Cousins, Serena Ryder, David Myles and many more. He currently resides on the south shore of Nova Scotia where he operates Dream Date Studio, a creative space for recording, sound and VST design, and writing. 
              </div>
            </div>

            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Henry.jpg' alt='Henry Mak' />
              </div>
              <div className='about-container--bio-text'>
                Henry Mak - director and editor of film content Dual Light, In a Landscape, Forever Young<br />
                Henry Mak is a videographer and editor who works in Dance, Music, and Community Arts. His work has screened at various festivals including the Cambodia International Film Festival, Festival Accès Asie, Movement Homescreen Festival, and Reelworld Film Festival. He is grateful to have the opportunity to work with Dreamwalker Dance Company, The Toronto Consort, Tribal Crackling Wind, Aeris Körper, MABELLEarts, CultureLink, Litmus Theatre, Shadowland Theatre, The Toronto Fringe and others. 
              </div>
            </div>

            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Eric.jpg' alt='Eric Chan' />
              </div>
              <div className='about-container--bio-text'>
                Eric Chan - director of photography Dual Light, In a Landscape, Forever Young<br />
                Eric's passion for the arts started early in theatre production. Having graduated from the Ryerson University program for Performance Production, he found his early years working with theatre, dance, and event companies. He later found work in television, where his newfound passion for creating from behind the lens grew. Over the years, Eric has been given the opportunity to collaborate with artists in the performance arts, even running into old friends along the way. He feels like in many ways, his journey has brought him back to his original love.
              </div>
            </div>

            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Yasuhiro.jpg' alt='Yasuhiro Okada' />
              </div>
              <div className='about-container--bio-text'>
                Yasuhiro Okada - director and editor of video content A Crazy Kind of Hope<br />
                Born in Hokkaido, Japan in 1986.  In 2011 He earned a Master's degree in Digital Content Management from DHU graduate school in Tokyo. In the same year, he was an awardee of Nikon photo contest for portrait work. He worked as a photo/videographer for Sobi-rokuon co., Sony Music, Columbia Records and Sony PCL in Tokyo. Since 2016, He has been based in Vancouver B.C. Canada, producing videos, photos and web content of dance and other digital educational content. He continues to expand his knowledge of digital platforms in support of arts and artists working to shift their creative works from live to digital transformation.
              </div>
            </div>

                       
            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Sophia.jpg' alt='Sophia Mai Wolfe' />
              </div>
              <div className='about-container--bio-text'>
                Sophia Mai Wolfe - assistant camera A Crazy Kind of Hope<br />
                Sophia (she/her) is a queer Japanese-Canadian dance artist whose practice is ever changing. She currently resides in what is colonially known as Vancouver on the territories of the xʷməθkwəy̓əm (Musqueam), Skwxwú7mesh (Squamish),and Səl̓ílwətaʔ/Selilwitulh (Tsleil-Waututh) Nations. Sophia has danced and toured internationally with companies and independent choreographers such as Company 605, Co-Erasga, Chick Snipper, Cindy Mochizuki, Kelly McInnes, Antonio Somera, Zahra Shahab, The Only Animal and New World Theatre. She holds an MA in Screendance from the London Contemporary Dance School with Distinction (2022), and is the founder and Artistic Director of F-O-R-M (Festival Of Recorded Movement), (<a href='http://www.f-o-r-m.ca' target='_blank' rel='noreferrer'>www.f-o-r-m.ca</a>). She is a video archivist and on the research team of videocan; an online archive of canadian performance. She has curated screenings for New Blue Dance Festival (Toronto), Vancouver Art Gallery, DOTE (Vancouver) and Body+Camera (Chicago).
              </div>
            </div>

            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Daniel.jpg' alt='Daniel Loan' />
              </div>
              <div className='about-container--bio-text'>
                Daniel Loan - director of photography A Crazy Kind of Hope<br/>
                Dan, a Vancouver-based filmmaker, began documenting live music and performance art in 2011 while managing an underground DIY venue. His work has contributed to award-winning documentaries, including 'Hayashi Studio' and 'Yintah,' set to premiere on CBC after winning the Cannes Marché du Film's 'Think-Film Impact Award.'<br/>
                "I feel blessed by the relationships I've formed through my involvement with VIDF beginning in 2020, when they adapted their performance season to livestream. Having grown up in a small-town dance environment, it's been a joy to be reintroduced into dancer space, interpret choreography, and blend movement & cinematography."<br/>
                Dan has collaborated with dance artists such as Kokoro, Company 605, Dance//Novella, Josh Beamish/MOVETHECOMPANY, and Ziyian Kwan/Dumb Instrument Dance. He is grateful for the opportunity to work with Dreamwalker Dance on 'Crazy Kind of Hope.'
              </div>
            </div>

            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Noah.jpg' alt='Noah Feaver' />
              </div>
              <div className='about-container--bio-text'>
                Noah Feaver - lighting designer Dual Light, In a Landscape<br />
                is a Dora Award nominated lighting designer. He works frequently in Dance and Opera and has designed for companies such as: Edmonton Opera, Toronto Dance Theatre, Tapestry Opera, Soulpepper Theatre Company, Holla Jazz, Citadel + Compagnie, Zata Omm, The Royal Conservatory of Music, Against the Grain Theatre, and Rock Bottom Movement. Noah holds a BFA from Toronto Metropolitan University’s Performance Production program and spent two summers as a lighting design practicum at The Banff Centre. He works as a Lighting Design Coach at The National Theatre School of Canada. Noah is a member of the Associated Designers of Canada. 
              </div>
            </div>

            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/James.jpg' alt='James Proudfoot' />
              </div>
              <div className='about-container--bio-text'>
                James Proudfoot - lighting designer A Crazy Kind of Hope<br />
                James Proudfoot is a lighting designer/director based in Vancouver, Canada. He is originally from Edinburgh, Scotland, where he received his initial theatre training. Self-taught in the realm of dance lighting, he has contributed designs to many artists and companies over the past 25 years. James is grateful to live and work on the unceded territories of the xʷməθkʷəy̓əm (Musqueam), Sḵwx̱wú7mesh (Squamish) and Sel̓íl̓witulh (Tsleil-Waututh) Nations.
              </div>
            </div>

            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Antoine.jpg' alt='Antoine Bédard' />
              </div>
              <div className='about-container--bio-text'>
                Antoine Bédard- sound design A Crazy Kind of Hope<br />
                Antoine Bédard is a composer and musician from Montreal (Canada) also known for his solo electro pop project called Montag (Carpark Records). He started working as a sound designer for theatre and dance in 2005 while living in Vancouver. Since moving back to Montreal in 2008, he has scored over fourty theatre and dance productions presented throughout Canada. He has composed music for a variety of artistic productions for companies such as The National Ballet of Canada, The National Film Board of Canada, and Robert Lepage’s Ex Machina. He has contributed to major projects by Montreal’s Moment Factory for the Boston Science Museum and the Tokyo transit company. Antoine was invited to represent Québec at the 2023 Prague Quadrennial of Performance Design and Space.
              </div>
            </div>
           
            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Jerome.jpg' alt='Jérôme Delapierre' />
              </div>
              <div className='about-container--bio-text'>
                Jérôme Delapierre - particle systems for ‘A River Beyond’<br />
                Jérôme Delapierre is a visual artist and interactive designer working in Montreal. He studied Computation Arts and Interactive design at Concordia University as well as Contemporary Arts and new media at IMUS University in France. Currently, he is the artistic director of Anartistic, and freelance visual designer and researcher at Topological Media Lab and Alkemie Atelier. He has collaborated with different artists and researchers like Coeur de pirate, Alex Nevsky, Cath Laporte, Dj Champion, Pk langshaw, Sha Xin Wei, Michael Montanaro, Roger Sinha and Jean Derome, and his work has been presented at festivals and events in various countries. His research is based on the relationship between human and technology and non linear interactivity, focusing on the experiences of urban social behaviour. He is interested in new ways to create visual sets and environments by exploring eclectic projection techniques. Jérôme's work encompasses: visual design, responsive video, interactive installations, performances and scenography. 
              </div>
            </div>
           

            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/AJ.jpg' alt='A.J. Morra' />
              </div>
              <div className='about-container--bio-text'>
                A.J. Morra - production manager<br />
                Based in Toronto, A.J. is a graduate of the Technical Theatre Program at Toronto Metropolitan University, and has enjoyed an active career as a Technical Director, Production Manager, and Stage Manager for contemporary dance, opera, circus, and theatre. Selected credits include three seasons with Toronto Dance Theatre, and assorted projects with ProArte Danza, Groundling Theatre Company, Holla Jazz, Zero Gravity Circus, Canadian Contemporary Dance Theatre, Soundstreams, and Signal Theatre.  A.J. has been working with Dreamwalker Dance Company since 2016. 
              </div>
            </div>
            
            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Cheryl.jpg' alt='Cheryl Lalonde' />
              </div>
              <div className='about-container--bio-text'>
                Cheryl Lalonde - costume and set design In a Landscape<br />
                Born and raised in Toronto, Cheryl Lalonde now resides in the paradise known as the Madawaska Valley. Leaving the city during the pandemic created an opportunity for Cheryl to begin exploring creative avenues of a more personal nature including quilting, felt work, knitting, and chickenology. She has returned to the city for only a few projects to work with artists she feels particularly attached to. Having collaborated with Andrea since the early days of Dreamwalker, she is delighted to be a part of a continued creative exploration, growth and development. Other recent collaborators include Lina Cruz (Fila 13), Natasha Powell (Holla Jazz), Jennifer Dallas (Kemi), and Sylvie Bouchard (Bouchardanse).
              </div>
            </div>
           
            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Richard.jpg' alt='Richard Nann' />
              </div>
              <div className='about-container--bio-text'>
                Richard Nann - river conversations <br />
                Richard was born in October 1930 in Victoria, B.C, the fourth child in a family of 6, and the first born son. The family moved to Vancouver when Richard was in elementary school and they were one of the few Chinese Canadian families who didn't live in Chinatown.  Richard excelled in school and loved competing in sports and taking on new challenges. He even took up the yoyo and would travel to different movie theatres on weekends where they would host yoyo competitions before screening a matinee!  Richard loves music and he and his friends would even drive to San Francisco and back the same night to attend live shows.  He would also sing his children to sleep every night and was Andrea’s first dance teacher.  She would stand on his toes and this is how she learned the foxtrot and waltz. Richard was the first person in his lineage to pursue post secondary education. His career was in Social Work and he received a scholarship to do his Masters degree at Columbia University in NYC and his Phd at Berkeley was also fully subsidized by the Canadian Government.  Richard returned to Canada after finishing his phd and became a professor at U.B.C.  Outside his responsibilities as an educator, Richard’s passion was research and his research took him into immigrant and refugee communities, primarily working in the intersections of social work and mental health.  Richard spent much of his career in Asia, visiting Vietnamese refugee camps in Thailand and setting up social work programs in China. At the peak of his career Richard stepped away and moved to a quiet place in nature on the Sunshine Coast to care for a family member who was in crisis.  In 2017 Richard moved to Toronto to live with Andrea, Andy and Owen and had a great time joining their busy lives.  He also took up painting and this became a joyful expression for him.  A talent he had never pursued!  Richard still finds joy and beauty in the simplest of life's pleasures. 
              </div>
            </div>
           
            <div className='about-container--bio-wrapper'>
              <div className='about-container--bio-photo'>
                <img src='/assets/images/bios/Justin.jpg' alt='Richard Nann' />
              </div>
              <div className='about-container--bio-text'>
                Justin Rutledge <br />
                Justin Rutledge is a Juno Award-winning songwriter from Toronto, now residing in Prince Edward County, Ontario. Rutledge has recorded 10 studio albums to date, and is also the recipient of a Canadian Folk Music Award and a Montreal English Theatre Award. 
              </div>
            </div>

          </div>
        </div>
      </div>
      <div
        className='about-container--button'
        onClick={() => {
          dispatch(toggleAbout(true));
        }}
      >
        About
      </div>
    </div>
  );
};
export default AboutContainer;
