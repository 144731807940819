import React, { useContext, useEffect, useState } from 'react';

import './the-river-two.scss';
import { setNextLevel } from '../../state/MainActions';
import { MainContext } from '../../state/MainContext';
import VideoPlayer from '../common/VideoPlayer';

const TheRiverTwo = () => {
  const [state, dispatch] = useContext(MainContext);
  return (
    <>
      <VideoPlayer />
    </>
  );
};

export default TheRiverTwo;
