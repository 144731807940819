import React, { useContext, useEffect, useState } from 'react';
import gsap from 'gsap';
import Sketch from 'react-p5';

import './the-pond.scss';
import { setNextLevel, setInteractionSrc, setSoundSrc } from '../../state/MainActions';
import { MainContext } from '../../state/MainContext';

const ThePond = () => {
  const [state, dispatch] = useContext(MainContext);

  const [isScrollVisible, setScrollVisible] = useState(false);
  const [currentDrop, setCurrentDrop] = useState(0);
  const [rippleSounds] = useState([
    { ref: undefined, path: 'assets/sounds/WaterDrop1.mp3' },
    { ref: undefined, path: 'assets/sounds/WaterDrop2.mp3' },
    { ref: undefined, path: 'assets/sounds/WaterDrop3.mp3' },
    { ref: undefined, path: 'assets/sounds/WaterDrop4.mp3' },
    { ref: undefined, path: 'assets/sounds/WaterDrop5.mp3' },
    { ref: undefined, path: 'assets/sounds/WaterDrop6.mp3' },
  ]);

  const [userRipple, setUserRipple] = useState({ x: -1, y: -1, diameter: 0, fade: 0 });
  const [otherRipples, setOtherRipples] = useState([]);
  const [rippleTimeout, setRippleTimeout] = useState(3000);

  const createParticle = (factor) => {
    const newArray = [];
    const newRipple = {
      x: Math.floor(Math.random() * window.innerWidth),
      y: Math.floor(Math.random() * window.innerHeight),
      diameter: 0,
      fade: 100,
      steps: Math.ceil(Math.random() * 2),
    };
    newArray[0] = newRipple;
    if (factor > 0.5) {
      const newRipple2 = {
        x: Math.floor(Math.random() * window.innerWidth),
        y: Math.floor(Math.random() * window.innerHeight),
        diameter: 0,
        fade: 100,
        steps: Math.floor(Math.random() * 2),
      };
      newArray[1] = newRipple2;
    }
    setOtherRipples(newArray);
    setRippleTimeout(Math.floor(Math.random() * 3000));
  };
  useEffect(() => {
    if (state.audioSrc === '') {
      dispatch(setSoundSrc(`/assets/sounds/ambience.mp3`));
    }
    if (state.nextLevel !== 0) {
      dispatch(setNextLevel(0));
    }

    const intervalId = setInterval(() => {
      createParticle(Math.random());
    }, 3000);
    // setTimeout(() => {
    //   createParticle(Math.random());
    // }, rippleTimeout);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     createParticle(Math.random());
  //   }, rippleTimeout);
  // }, [rippleTimeout]);

  useEffect(() => {
    dispatch(setInteractionSrc(rippleSounds[currentDrop].path));
  }, [currentDrop]);

  useEffect(() => {
    if (isScrollVisible) {
      gsap.to('.pond-scroll', {
        opacity: state.nav.expanded ? 0 : 1,
        duration: 1,
      });
    }
  }, [state.nav.expanded]);

  const onMousePressed = (p5) => {
    setUserRipple({ x: p5.mouseX, y: p5.mouseY, fade: 100, diameter: 0 });

    if (currentDrop < 5) {
      setCurrentDrop(currentDrop + 1);
    } else {
      setCurrentDrop(0);
    }
  };

  const setup = (p5, canvasParentRef) => {
    p5.pixelDensity(1);
    p5.imageMode(p5.CENTER);
    const cnv = p5.createCanvas(window.innerWidth, window.innerHeight).parent(canvasParentRef);
  };

  const draw = (p5) => {
    p5.background(0);

    // user
    if (userRipple.fade > 0) {
      p5.noFill();
      p5.stroke(`rgba(255,255,255,${userRipple.fade / 100})`);
      p5.circle(userRipple.x, userRipple.y, userRipple.diameter * 2);
      p5.stroke(`rgba(255,255,255,${p5.max(userRipple.fade * 0.6, 0) / 100})`);
      p5.circle(userRipple.x, userRipple.y, userRipple.diameter * 2.7);

      setUserRipple({ ...userRipple, fade: userRipple.fade - 1, diameter: userRipple.diameter + 1 });
    }

    // others
    const newRipples = [];
    otherRipples.forEach((ripple) => {
      if (ripple.fade > 0) {
        p5.noFill();
        p5.stroke(`rgba(255,255,255,${ripple.fade / 100})`);
        p5.circle(ripple.x, ripple.y, ripple.diameter * 2);
        p5.stroke(`rgba(255,255,255,${p5.max(ripple.fade * 0.6, 0) / 100})`);
        p5.circle(ripple.x, ripple.y, ripple.diameter * 2.7);

        newRipples.push({
          x: ripple.x,
          y: ripple.y,
          fade: ripple.fade - 1,
          diameter: ripple.diameter + 1,
          steps: ripple.steps,
        });
      }
    });
    // console.log(newRipples);
    setOtherRipples(newRipples);
  };

  return (
    <div className='pond-container'>
      <div className='pond-background'>
        <video muted autoPlay loop>
          <source src='https://allofourdreaming.s3.amazonaws.com/pond-background.mp4' type='video/mp4' />
        </video>
      </div>
      <div className='pond-ripples'>
        <Sketch setup={setup} draw={draw} mousePressed={onMousePressed} />
      </div>
    </div>
  );
};
export default ThePond;
