import { useContext, useEffect, useRef, useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';

import './video-player.scss';
import { MainContext } from '../../state/MainContext';
import { useLocation } from 'react-router-dom';
import { allowTransition, setNextLevel } from '../../state/MainActions';

const VideoPlayer = ({ url = '', showControls = true, showCustomPlay = false,  hasVideoEnded = null}) => {
  const [state, dispatch] = useContext(MainContext);
  const [videoPlayerClass, setVideoPlayerClass] = useState('');
  const [videoURL, setVideoURL] = useState('');
  const [controls, setShowControls] = useState(true);
  const [showPlayBtn, setShowPlayBtn] = useState(true);
  const [isPaused, setVideoIsPaused] = useState(false);
  const location = useLocation();

  const vidPlayer = useRef();

  useEffect(() => {
    const entries = state.levels.filter((level) => level.slug === location.pathname);
    setShowControls(showControls)
    setShowPlayBtn(showCustomPlay)
    setVideoIsPaused(showCustomPlay)
    if (url !== '') {
      setVideoURL(url);
    } else if (entries && entries.length > 0) {
      const [{ video }] = entries;
      setVideoURL(video);
    }
  }, [location.pathname, state.levels]);

  const [videoPreloading, setVideoPreloading] = useState(false);

  //   const onStarClicked = (id) => {
  //     setVideoPreloading(true);
  //   };

  const onVideoEnd = () => {
    // console.log('onVideoEnd');
    if (hasVideoEnded) {
      hasVideoEnded(true)
    }
    setVideoPlayerClass('');
    let currentIndex = -1;
    state.levels.forEach((level, index) => {
      if (level.slug === location.pathname) {
        currentIndex = index;
      }
    });
    if (currentIndex >= 0) {
      const current = state.levels[currentIndex];
      const next = state.levels.findIndex((element) => element.slug === current.next);
      // console.log(current.slug, current.next, next);
      if (current.next === '/where-to-next') {
        dispatch(allowTransition(false));
      } else if (current.next === '/the-end') {
        dispatch(allowTransition(false));
      } else {
        dispatch(allowTransition(true));
      }
      dispatch(setNextLevel(next));
    }
  };

  const onVideoStart = () => {
    // console.log('onVideoStart');
    setVideoPlayerClass('videoPlayer--idle');
    setVideoPreloading(false);
    // console.log(document.getElementById('video'))

  };

  const onVideoProgress = (event) => {
    const { seconds, duration } = event;
    if (duration - seconds < 0.51 && videoPlayerClass === 'videoPlayer--idle') {
      setVideoPlayerClass('videoPlayer--fadeout');
    } else {
    }
    if (seconds > 0) {
      setShowPlayBtn(false)
    }
  };

  return (
    <>
      <div
        className='videoPlayer--cover'
        style={{
          backgroundColor: videoPlayerClass === 'videoPlayer--idle' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0)',
        }}
      >
        {videoPreloading && <img src='/assets/images/loading.gif' alt='Video Preloading' />}
      </div>
      {videoURL !== '' && (
        <div className='videoPlayer--container'>
          <Vimeo
            ref={vidPlayer}
            className={`videoPlayer ${videoPlayerClass}`}
            style={{ width: '100vw', height: '100vh' }}
            video={videoURL}
            controls={controls}
            showTitle={false}
            showByline={false}
            paused={isPaused}
            autoplay
            width={'100%'}
            height={'100%'}
            responsive={true}
            onEnd={onVideoEnd}
            onReady={onVideoStart}
            onTimeUpdate={onVideoProgress}
          />
        </div>
      )}
      {!videoPreloading && showPlayBtn && 
        <div className='videoPlayer--controls'>
            <div onClick={() => {
                  vidPlayer.current.player.play()
                  setShowPlayBtn(false)
                }}>
              <img src='/assets/images/playIntroVideo.png' alt='Play Video' />
            </div>
        </div>
      }
    </>
  );
};

export default VideoPlayer;
