export default class Particle {
  constructor(p5, cols, scl, width, height, mouseX, mouseY) {
    this.p5 = p5;
    this.cols = cols;
    this.scl = scl;
    this.width = width;
    this.height = height;
    const x = mouseX >= 0 ? mouseX + Math.floor(Math.random() * 40) - 20 : Math.floor(Math.random(width) * 1000);
    const y = mouseY >= 0 ? mouseY + Math.floor(Math.random() * 40) - 20 : Math.floor(Math.random(height) * 1000);

    this.pos = p5.createVector(x, y);
    this.vel = p5.createVector(0, 0);
    this.acc = p5.createVector(0, 0);
    this.maxSpeed = 2;

    this.prevPos = this.pos.copy();
  }

  update() {
    this.vel.add(this.acc);
    this.vel.limit(this.maxSpeed);
    this.pos.add(this.vel);
    this.acc.mult(0);
  }

  applyForce(force) {
    this.acc.add(force);
  }

  show(colorfield) {
    this.p5.strokeWeight(1);
    this.p5.line(this.pos.x, this.pos.y, this.prevPos.x, this.prevPos.y);
    this.updatePrev();
    //point(this.pos.x, this.pos.y);
  }

  inverseConstrain(pos, key, f, t) {
    if (pos[key] < f) {
      pos[key] = t;
      this.updatePrev();
    }
    if (pos[key] > t) {
      pos[key] = f;
      this.updatePrev();
    }
  }

  updatePrev() {
    this.prevPos.x = this.pos.x;
    this.prevPos.y = this.pos.y;
  }

  edges() {
    this.inverseConstrain(this.pos, 'x', 0, this.width);
    this.inverseConstrain(this.pos, 'y', 0, this.height);
  }

  follow(vectors, colorfield) {
    let x = Math.floor(this.pos.x / this.scl);
    let y = Math.floor(this.pos.y / this.scl);
    let index = x + y * Math.floor(this.width / this.scl);
    let force = vectors[index];
    this.applyForce(force);
    let c = colorfield[index];
    if (c) {
      this.p5.stroke(this.p5.color(c[0], c[1], c[2]));
    }
  }
}
