import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { enterSiteClicked } from '../../state/MainActions';
import { MainContext } from '../../state/MainContext';
import VideoPlayer from '../common/VideoPlayer';

import './intro.scss';

const Intro = () => {
  const [state, dispatch] = useContext(MainContext);
  const [redirection, setRedirection] = useState('');
  const [hasSeenIntro, setHasSeenIntro] = useState(false)
  const [hasVideoEnded, setHasVideoEnded] = useState(false)

  
  useEffect(() => {
    setHasSeenIntro(localStorage.getItem('seen_intro') === 'true')
  }, [hasSeenIntro]);

  useEffect(() => {
    if (state.isAuth) {
      setRedirection('/the-pond');
    }
  }, [state.isAuth]);

  useEffect(() => {
  //  console.log(':::: Video Ended', hasVideoEnded)
   if (hasVideoEnded) {
    setHasSeenIntro(true)
    localStorage.setItem('seen_intro', true)
   }
  }, [hasVideoEnded]);

  return (
    <div className='site-intro'>
      {redirection !== '' && <Navigate push to={redirection} />}
      
      { !hasSeenIntro && 
        <VideoPlayer 
            url='https://vimeo.com/873328098/3800ec8d45' 
            showControls={false} 
            showCustomPlay={true}
            hasVideoEnded={setHasVideoEnded}
            /> 
      }
      { hasSeenIntro && <div className='enter-container'>
        <div className='intro-title'>
          <img src='/assets/images/StartPageTitle.png' alt='All of our dreaming' />
        </div>
        {process.env.REACT_APP_ALLOW_ENTER.toString() === 'true' && (
          <div className='intro-buttonContainer'>         
            <button
              onClick={() => {
                dispatch(enterSiteClicked());
              }}
            >
              Enter Site
            </button>
            <button
              onClick={() => {
                localStorage.setItem('seen_intro', false)
                setHasVideoEnded(false)
                setHasSeenIntro(false);
              }}
            >
              Replay Intro
            </button>
          </div>

        )}
        <div className='intro-subtext'>
          This experience works best with Google Chrome browsers on laptops or desktop computers.
          <a href='https://www.google.com/intl/en_ca/chrome/' target='_blank' rel='noreferrer'>
            Get it here
          </a>
          . Headphones recommended.
        </div>
        <div className='intro-headphones'>
          <img src='/assets/images/headphones.png' alt='Usew headphones' />
        </div>
      </div>}
    </div>
  );
};

export default Intro;
