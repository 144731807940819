import VideoPlayer from '../common/VideoPlayer';
import './forever-young.scss';

const ForeverYoung = () => {
  return (
    <>
      <VideoPlayer />
    </>
  );
};
export default ForeverYoung;
