import VideoPlayer from '../common/VideoPlayer';
import './in-landscape.scss';

const InLandscape = () => {
  return (
    <>
      <VideoPlayer />
    </>
  );
};
export default InLandscape;
