import VideoPlayer from '../common/VideoPlayer';
import './the-end.scss';

const TheEnd = () => {
  return (
    <>
      <VideoPlayer />
    </>
  );
};
export default TheEnd;
