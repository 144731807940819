import VideoPlayer from '../common/VideoPlayer';
import './crazy-kind-hope.scss';

const CrazyKindHope = () => {
  return (
    <>
      <VideoPlayer />
    </>
  );
};
export default CrazyKindHope;
