import { useContext } from 'react';
import { MainContext } from '../../../state/MainContext';
import './nav-planes.scss';

const NavPlanes = ({ hovered }) => {
  const [state, dispatch] = useContext(MainContext);
  return (
    <div className='nav-planes'>
      <div className='nav-planes-container'>
        {state.levels
          .filter((element) => element.show)
          .map((element, index) => {
            return (
              <div
                key={`nav_planes_item_${index}`}
                id={`nav_planes_item_${index}`}
                className='nav-planes-item'
                style={{
                  zIndex: state.levels.length - index,
                  top: index * -200,
                  opacity: hovered === index ? 1 : hovered > -1 ? 0.3 : 1,
                }}
              >
                <img src={`/assets/images/nav${element.slug}.png`} alt='go to link' />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default NavPlanes;
